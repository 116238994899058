:root {
    --theme-color: #0d7bc4;
    --button-hover-color: #03568a;
    --bulue-color: #0079C2;
    --main-color: #ecf0f18a;
    --first-point-color: #C9EEFF;
    --second-point-color: #62CDFF;
    --third-point-color: #4E31AA;
    --size: 5px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

/* ************************************** LOADING ANIMATION CSS *(****************** */

.loader {
    background-color: var(--main-color);
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0; left: 0;
    display: flex;
    align-items: center;
    align-content: center; 
    justify-content: center;  
    z-index: 100000;
  }
  
  .loader__element {
    border-radius: 100%;
    margin: calc(var(--size)*2);
  }
  
  .loader__element:nth-child(1) {
    animation: preloader .6s ease-in-out alternate infinite;
    border: var(--size) solid var(--first-point-color);
  }
  .loader__element:nth-child(2) {
    animation: preloader .6s ease-in-out alternate .2s infinite;
    border: var(--size) solid var(--second-point-color);
  }
  
  .loader__element:nth-child(3) {
    animation: preloader .6s ease-in-out alternate .4s infinite;
    border: var(--size) solid var(--third-point-color);
  }

/* ************************************** END LOADING ANIMATION CSS *(****************** */


.input-placeholder-style::placeholder{
    color: white;
    font-weight: 600;
}

.figure-text-sizing h2 {
    font-size: 20px;
}

.figure-text-sizing p {
    font-size: 15px;
    color: #656565;
}

.figure-image {
    border-radius: 15px !important;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.filter-button-size-style {
    background-color: var(--bulue-color);

    font-size: 1.2rem !important;
    bottom: 15px;
    right: 15px;
    z-index: 1;
}

.filter-button-size-style:hover {
    background-color: rgba(41, 93, 161, 0.788);
}

.image-div {
    height: 275px;
    background-position: center;
    background-size: contain;
}

.multipleImages {
    height: 115px;
}

.imgSizeStyle {
    object-fit: cover;
    /* border-radius: 6px !important; */
}

.hotel-card-style {
    border-radius: 10px !important;
    overflow: hidden;
}

.main-text-size-style h1 {
    font-size: 36px !important;
}

/* ******************************** signIn signUp card css ************************************ */
.card-display-style {
    margin-right: -50px;
    background: hsla(0, 0%, 100%, 0.55);
    backdrop-filter: blur(30px);
}

.sign-in-dropdown{
    transform: translate(-38px, 38px) !important;
}

.sign-in-dropdown {
    transform: translate(-38px, 38px) !important;
}

/* ************************ homepage css ************* */


/* ************************ Hotel CSSS **************************** */

.destination-main-title {
    font-size: 3.125rem !important;
    font-family: 'Nunito' !important;
    font-weight: 800 !important;
    text-shadow: 1px 1px 2px black !important;
}

.center {
    padding-top: 180px;
    padding-bottom: 290px;
}

.rowPaddingStyle {
    padding-left: 150px;
    padding-right: 150px;
}

#auth-email-confirmation-submit-button {
    color: #fff;
    background-color: var(--theme-color);
    border: 0;
}

.submit-button:hover {
    background-color: var(--button-hover-color) !important;
}

.link-text-color {
    color: #6B728E;
    font-weight: bold;
}

/* ******************** SPECIFIC HOTEL CSS **************** */
.googlemapImage {
    background-size: cover;
    background-position: center;
}

.paragraphWidth {
    text-align: justify;
}


.signin-input-container-input {
    background-color: #CBF2FC !important;
    margin-bottom: 25px;
}

.signin-input-container .select {
    background-color: #B4EBF9;
    margin-bottom: 25px;
}

.signin-input-container .button-continue {
    background-color: #0D7BC4;
    margin-bottom: 15px;
    padding: 5px !important;
}

.signin-input-container .button-continue:hover {
    background-color: #0D7BC4a9;
    color: rgba(34, 33, 33, 0.863) !important;
}

.signin-input-container .button-facebook {
    background-color: #B4EBF9;
    padding: 6px;
}

.react-tel-input input,
.flag-dropdown {
    border: 1px solid !important;
}

.react-tel-input .form-control {
    width: 100% !important
}

.css-13cymwt-control {
    /* background-color: #B4EBF9 !important; */
    border-radius: 6px !important;
    border: none !important;
}

.signin-hr {
    border-top: 2px solid black !important;
    color: black !important;
    opacity: 1 !important;
}

.signin-input-width {
    width: 75%;
}

.login-input-width {
    margin-bottom: 10px;
    background-color: #B4EBF9;
    width: 75%;
}

.sign-in-checkbox {
    width: 75%
}

.sign-in-text-size h1 {
    font-size: 40px;
}

.sign-in-text-size p {
    font-size: 13px;
}

.sign-in-text-size span {
    font-size: 48px;
}

.card-border-radius {
    border: 2px solid #393053 !important;
}

.input-group-boder-check-in {
    border-right: 1px solid;
}

.input-group-boder-check-in span {
    background-color: white !important;
}

.input-group-boder-check-out {
    border-left: 1px solid;
}

.input-group-boder-check-out span {
    background-color: white !important;
}

.input-bottom-border {
    border-bottom: 1px solid;
}

.specific-hotel-container {
    margin-top: 44px !important;
}

/* ************** date picker model css ****************** */
.date-range-btn {
    font-weight: 500;
    color: #00b8ff;
    width: 70px;
}

.date-range-btn:hover {
    background-color: #00b8ff;
}

.daterange-display {
    display: none;
}

.date-range-picker {
    left: 25px;
    z-index: 3;
    background-color: white;
}


/* ************************ Verify your property page css ****************** */
.property-footer-button {
    background-color: #CBF2FC;
    font-size: 15px;
}

.property-footer-button:hover {
    background-color: #9ad9e9;
    font-size: 15px;
}

.headerText a,
h5 {
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 0.04cm;
}

.propertyHeaderWidth {
    width: 200px;
    height: 100px;
}

.header-section-padding {
    padding-left: 3rem;
    padding-right: 3rem;
}

.welcome-text h1 {
    font-size: 20px;
    font-weight: 600;
}

.welcome-text span {
    color: #B4EBF9;
    font-size: 23px;
}

.welcome-text h3 {
    font-size: 15px;
    font-weight: 600;
}

.welcome-text p {
    font-size: small;
    width: 85%;
}

.welcome-text h6 {
    font-size: 15px;
    font-weight: 400;
}

.welcome-images {
    width: 100px;
    height: 100px;
}

.welcome-images img {
    height: 100%;
}

.fourteen-step-text h6,
span {
    font-size: 15px;
    font-weight: 600;
}

.fiveteen-step-text h6 {
    font-size: 15px;
}

.fiveteen-step-text h2 {
    font-weight: 600;
    font-size: 16px;
}

.catagory-select {
    background-color: red;
}

/* ****************************** WEHOST CSS *************************** */
.wehost-main-container {
    padding-left: 40px;
}

.wehost-main-container h1 {
    font-size: 20px;
    font-weight: 900;
}

.wehost-main-container p {
    font-size: 16px;
    text-align: justify;
    width: 90%;
}

.wehost-h6-div {
    width: 75%;
    background-color: #0D7BC4;
}

.wehost-h6-div h6 {
    font-size: 15px;
}

.wehost-card-position {
    position: relative;
    top: -118px;
}

.wehost-card-position-2 {
    position: relative;
    top: -100px;
}

.wehost-input span {
    background-color: white;
}

.wehost-input {
    border: 1px solid #30a4ec;
}

.wehost-house-img {
    width: 80%;
    padding: 50px 0;
}

.wehost-img {
    height: 300px;
    object-fit: cover;
}

.wehost-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.wehost-button button {
    background-color: #007AC3;
    color: white;
    font-size: 15px;
}

.wehost-button i {
    font-size: 18px;
}

.wehost-button button:hover {
    background-color: #0771adbd;
    color: white;
}

.daterange-display .search-dropdown {
    width: fit-content !important;
}

.wehost-bg-img {
    background: url('/public/images/Group12.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 330px;
    padding-bottom: 150px;
    height: 100%;
    width: 100%;
}

.wehost-bg-img-text h1 {
    font-size: 30px;
    margin-bottom: 80px;
    font-weight: 700;

}

.wehost-conviction-text h1 {
    font-size: 1.5rem;
    font-weight: 600;
}

.wehost-profile-pic {
    width: 100px;
    height: 100px;
}

.wehost-profile-text {
    width: 75%;
}

.wehost-profile-text h6 {
    font-size: 15px;
}

/* ****************************** First Step CSS *********************** */
.firstStep-text h1 {
    font-size: 20px;
}

.firstStep-text {
    width: 90%;
}

.firstStep-text h6,
h4 {
    font-size: 15px;
    text-align: justify;
}

.firstStep-text h1 {
    font-weight: 900;
}

.firstStep-text h6,
h4 {
    color: #7B8FA1
}

.firstStep-img {
    width: 400px;

}

.firstStep-img img {
    width: 100%;
    height: 100%;
}

/* Footer Style start */
/* Header */
li {
    list-style: none;
}

a {
    text-decoration: none;
}

.header-logo {
    width: auto;
    height: 10vh !important;
}

.header-auth-dropdown {
    position: relative;
}

.menu-profile-icon {
    margin-right: 8px;
    width: 33px;
    height: 33px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
}

.hosting-tenth-step-submit {
    transition: all 300ms ease;
}

.hosting-tenth-step-submit:hover {
    transform: translate(-1px, -1px);
    box-shadow: 2px 2px 0px #bfbfbf;
}

.header-main-menu a {
    color: rgb(17, 16, 16);
}

.header-auth-menu {
    position: absolute;
    width: 100%;
    height: fit-content;
    background: #fff;
    border-radius: 8px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    opacity: 0;
    pointer-events: none;
}

.header-auth-menu-icon {
    font-size: 14px;
}

.header-auth-menu-item {
    color: #000;
}

.header-auth-menu-active {
    animation: headerAuthItemActive 0.3s ease forwards;
}

.header-auth-menu-inactive {
    animation: headerAuthItemInactive 0.3s ease forwards;
}

.header-auth-inner-menu {
    display: flex;
    flex-direction: column;
    padding-top: 10px;
    padding-bottom: 10px;
}

.header-main-menu {
    margin-right: 32px;
}

.menu-item {
    font-size: 15px;
}

.header {
    padding-right: 3rem !important;

    padding-left: 3rem !important;
}

.auth-item {
    margin-left: 4px;
}

.menu-dropdown {
    width: -webkit-fill-available;
    display: none;
    justify-content: end;
    align-items: center;
}

.menu-dropdown i {
    transform: scale(2.3);
}

.menu-toggle {
    cursor: pointer;
}

.menu-mbl-item {
    font-size: 18px !important;
    width: fit-content;
}

.menu-collapse {
    position: absolute;
    background-color: #f8f9fa;
    z-index: 1000;
    height: 100vh;
    width: fit-content;
    margin-left: -12px;
    padding-top: 20px;
    padding-right: 50px;
    overflow: hidden;
    transform: translateX(-100%);
    transition: all 0.3s ease-in !important;
}
.real-state-host{
    margin-top: 21% !important;
}
.bg-realstate{
    padding-top: 10%;
    padding-bottom: 10%;
    background: #CBF2FC;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.btn-check-home{
    width: fit-content;
    background: #0079C2;
border: 1px solid #000000;
border-radius: 7px;
color: #fff;
}
.realstate-text{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 27px;
color: #000000;
margin-top: 3%;
}
.menu-active {
    animation: dropdownActive 0.4s ease-in forwards;
}

.menu-inactive {
    animation: dropdownInActive 0.4s ease-in forwards;
}

/* ------------- */

/* Footer */
.footer-title.ftitle {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 124.5%;
    color: #080809;
}

.nav-des {
    display: block;
    /* padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x); */
    font-size: var(--bs-nav-link-font-size);
    font-weight: var(--bs-nav-link-font-weight);
    /* color: var(--bs-nav-link-color); */
    text-decoration: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out;
    color: #5e6282;
    /* padding: 1rem; */
    padding-left: 6px;
    padding: 3px;
    margin-left: 2px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 124.5%;
}

.copyright {
    font-size: 20px;
    line-height: 33px;
    color: #ffffff;
    text-shadow: 1px 2px 2px rgb(49, 48, 48) !important;
}

.text-center.fcopyright {
    background-color: #0579c1;
}

.container.prefooter {
    background-color: #b4ebf9;
}

.inquirysection {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 14px;
}

#form5Exam {
    font-size: 15px;
}

/* Main */
.section-title {
    font-size: 28px;
}



.owl-dots {
    display: none;
}

.owl-nav button {
    transform: scale(1.7);
}

.owl-image {
    height: 38vh;
    width: 100%;
    object-fit: cover;
}

.owl-title {
    font-size: 24px;
    margin-left: 15px;
}

.owl-views {
    color: #939393;
    font-size: 12px;
    margin-left: 15px;
}

.item {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding-bottom: 15px;
}

.owl-stage-outer {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
}

/* .contaner{
    position: relative;
    text-align: center;
    color: white;
} */
.bg-overlay {
    /* background-image: url('/src/components/homePageComponents/destination/bgimage1.svg'); */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    /* padding: 263px; */
}

.destination-main-bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
}

.center {
    /* position: absolute;
    top: 49%;
    left: 10%;
    transform: translate(-4%, -50%); */
    /* overflow: hidden; */
    padding-top: 150px;
    padding-bottom: 140px;
    position: relative;
}

input {
    border: none;
}

.check-in {
    height: 56px;
    width: 553px;
}

.scroll {
    overflow-x: scroll;
}



/* .destination-main-title {
    font-size: 66px !important;
    text-shadow: 1px 2px 5px black !important;
} */

.destination-input input {
    font-size: 19px;
}

.destination-input a {
    font-size: 15px;
    font-weight: 400 !important;
}

.destination-input span {
    background-color: white;
    user-select: none;
}

.destination-input span i {
    transform: scale(1.3);
}

.destination-input span .bi-searchIcon {
    margin-left: 238px;
    position: absolute;
    z-index: 100;
}

.bottom-button {
    background: #0d7bc4 !important;
    border-color: #0d7bc4 !important;
    font-size: 17px;
    padding-right: 20px;
}

.button-column #hotels::before {
    content: "\F1DD";
    margin-right: 9px;
}

.bottom-buttons .row {
    margin-top: -15px;
}

.button-column #things::before {
    content: "\F473";
    margin-right: 9px;
    transform: scaleY(1.2);
}

.button-column #vacations::before {
    content: "\F422";
    margin-right: 9px;
}

.button-column #fares::before {
    content: "\F247";
    margin-right: 9px;
}

.add-listing:hover {
    background-color: #005286;
    color: #ffff;

}

.button-column #more::before {
    content: "\F5D4";
    margin-right: 9px;
}

.button-column #filter::before {
    content: "\F56B";
    margin-right: 9px;
}

.button-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
}

.button-column button {
    width: fit-content;
    box-shadow: 1px 1px 4px black;
    font-size: 15px;
}

.buttons-table {
    min-width: 1000px;
}

.table-responsive-xl {
    margin-top: -21px;
}

.table-responsive-xl::-webkit-scrollbar {
    width: 20px;
    height: 8px;
}

.table-responsive-xl::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.table-responsive-xl::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

.table-responsive-xl::-webkit-scrollbar-thumb:hover {
    background: gray;
}





.imgText {
    position: relative;
    bottom: 40px;
    font-weight: 700px;
    font-size: 30px;
}

.coverImage {
    background-size: contain;
    background-position: center;
}

.active-category {
    /* background-color: #F0EEED!important; */
    border: 2px solid #000000 !important;
    border-radius: 2px;
}

/* .imageTextColor:hover{
    background: #0d7bc411;

} */

.img-overlay:hover {
    background: #0d7bc411;

}

.imageTextColor {
    color: white
}

.img-overlay {
    background: #0D7BC45c;
}

.explore {
    background-color: #0579C1;
    width: 200px;
}



.explore-title {
    font-size: 31px !important;
}

.explore-description {
    font-size: 15px !important;
    color: #787777 !important;
}

.explore-images {
    width: 100%;
    object-fit: cover;
    cursor: pointer;
}

.explore-image-1 {
    height: 100%;
}

/* .explore-image-4 {
    height: 83vh;
} */

.overly-1 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0d7bc44f;
}

.overly-2 {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50%;
    background-color: #0d7bc44f;
}

.overly-3 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 48.5%;
    background-color: #0d7bc44f;
}

.images-section-1 {
    width: 49.5%;
}

.images-section-2 {
    width: 49.5%;
    margin-left: 1%;
}

.images-section-3 {
    width: 49.5%;

}

.explore-images {
    display: flex;
}

li.list-group-item.guesthouse {
    width: 125px;
    height: 53px;
}

.guestimg {
    margin-top: 12px;
}

.overly-1,
.overly-2,
.overly-3 {
    color: white;
    font-size: 17px;
    font-weight: 600;
    text-shadow: 1px 2px 5px black !important;
    display: flex;
    align-items: flex-end;
    padding-bottom: 17px;
    padding-left: 42px;
}

.explore-button {
    border: 0;
    background-color: #0579C1;
    color: white;
    font-size: 15px !important;
    min-width: 160px;
    min-height: 45px;
    transition: all 0.3s ease-in;
}

.card-title {
    font-size: 15px;
    font-weight: 600;
}

.card-body .card-text {
    font-size: 15px;
    color: #717171;
}

.explore-button:hover {
    background-color: var(--button-hover-color);
}

.button-icon {
    margin-left: 4px;
}



.backcolor {
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.5);
    width: 400px;
    margin: auto;
    border-radius: 5px;
}

.backcolor p {
    color: black;
    margin: 0;
}

.figCaptionDisplay {
    position: absolute;
    bottom: 15rem;
    width: 100%;
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: white;
}

.figCaptionDisplay p {
    text-shadow: 1px 3px 4px #FFFBEB;
}



.things-to-explore-text {
    text-align: left !important;
}


.things-to-explore-text h2 {
    font-size: 30px !important;
    text-shadow: 1px 3px 4px #FFFBEB;
    margin-bottom: 0;
}

.things-to-explore-text h5 {
    font-size: 20px !important;
    text-shadow: 1px 3px 4px #FFFBEB;
    margin-bottom: 1;
}

.things-to-explore-text button {
    width: 7em;
    font-size: 15px !important;
    padding: 1px !important;
}

.carousel-caption {
    bottom: -0.75rem !important;
    left: 2% !important;
}

.backgroundShadow {
    background: rgba(0, 0, 0, 0.1) !important;
}



/* button{
    padding: 10px !important;
    font-size: 1.2rem !important;
} */

.image-div {
    height: 275px;
    border-radius: 10px !important;
    background-position: center;
    background-size: contain;
    overflow: hidden;
}

.multipleImages {
    height: 115px;
}

.imgSizeStyle {
    object-fit: cover;
    /* border-radius: 6px !important; */
}

.main-text-size-style h1 {
    font-size: 36px !important;
}



/* .destination-main-title {
    font-size: 17px !important;
    text-shadow: 1px 2px 5px black !important;
} */
.formulatxt {
    font-size: 1.5rem;
    font-weight: 500;
    text-shadow: 1px 1px 2px black !important;
}

input.form-control.border-0.shadow-none.searchfeildtxt {
    font-size: 14px !important;
}

.border-color {
    border-color: #97C1C6 !important;
}

.hotel-background-image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.divBackgroundImage {
    /* background-image: url('/src/components/hotelscomponents/hotel/hotel.svg'); */
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
}


.center {
    padding-top: 180px;
    padding-bottom: 290px;
}

.check-in {
    height: 56px;
    width: 553px;
}

.scroll {
    overflow-x: scroll;
}

.rowPaddingStyle {
    padding-left: 150px;
    padding-right: 150px;
}



.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-card {
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    width: 500px;
    max-width: 90%;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.modal-header h2 {
    margin: 0;
}

.close-button {
    background-color: transparent;
    border: 0;
    color: black;
    font-size: 1.5rem;
    cursor: pointer;
}

.modal-body {
    padding: 1rem;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid lightgray;

}

.modal-footer-button {
    background-color: #0D7BC4;
    border: 0;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    cursor: pointer;
    color: white;
}

.modal-footer-button:hover {
    background-color: #B4EBF9;
    color: white;
}

.search-container {
    display: flex;
    align-items: center;
    background-color: lightgray;
    border-radius: 5px;
    padding: 0.5rem;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

.search-input {
    border: 0;
    background-color: transparent;
    outline: none;
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
}

.search-icon {
    font-size: 1.5rem;
    color: gray;
    margin-left: 0.5rem;
}


.modaldes {
    display: flex;
    align-items: center;
    color: #333;
    text-decoration: none;
}

.modaldes:hover {
    color: #333;
}

.list-btn-guesthouse {
    height: 16px;
    margin-top: 5px;

}

.locationimg {
    border: 1px solid;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    padding: 2px;
    padding-left: 5px;
}

.searchimg {
    border: 1px solid;
    border-radius: 18px;
    height: 29px;
    width: 30px;
    padding: 2px;
    padding-left: 6px;
    color: #080809;
}

.geoimg {
    border: 1px solid;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    padding: 2px;
    padding-left: 6px;
}

.Nearbytxt {
    margin-left: 2%;
}
/* More Option menu slider */
.menu-slider {
  width: 100%;
  margin: 0 auto;
}

.menu-item {
  margin-right: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  cursor: pointer;
}

.menu-item.active {
  color: #fff;
  background-color: #333;
  border-radius: 20px;
  padding: 5px 15px;
}

/* .bi.bi-geo-alt-fill {
  border: 1px solid;
  border-radius: 20px;
  height: 30px;
  width: 30px;
  padding: 2px;
  padding-left: 6px;
} */
.owl-theme .owl-nav {
    position: absolute;
    top: 10px;
    width: 100%;
    display: flex !important;
    justify-content: space-between !important;
    margin: 0 -8px;
    margin-top: 0px !important;
}
.owl-carousel .owl-nav button.owl-next{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    z-index: 2;
}
.owl-carousel .owl-nav button.owl-prev{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    z-index: 2;
}
.owl-carousel .owl-nav button.owl-prev:hover{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 3px;
}
.owl-carousel .owl-nav button.owl-next:hover{
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 3px;
}
.owl-carousel.owl-theme.owl-loaded.owl-drag{
    position: relative;
    border-bottom: none !important;
}
.owl-carousel .owl-stage-outer {
    padding: 0 !important;
}
.owl-carousel.owl-theme.owl-loaded.owl-drag{
    height: 80px;
    border-bottom: 0.5px solid #e0e0e0;
    border-top: 0.5px solid #e0e0e0 ;
}

.owl-stage-outer{
    z-index: 1;
}

.owl-item{
    cursor: pointer;
}

.category-list:hover{
    border-bottom: 2px solid black;
    color: black !important
}

.destinationtxt {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 41px;
    color: black;
}

.modalview {
    z-index: 10px;
}

.owl-nav {
    display: flex !important;
    justify-content: center !important;
    
}

.owl-nav .owl-prev {
    margin-right: 5px;
}

.owl-nav .owl-next {
    margin-left: 5px;
}

.owl-nav .disabled {
    color: gray !important;
}

.service-section-title-border span {
    position: absolute;
    width: 37px;
    height: 4px;
    background: #0D7BC4;
    margin-top: -5px;
}

.section-titles {
    font-size: 20px !important;
    font-weight: 700;
}

.worldwide-tab-title {
    padding-bottom: 10px;
    font-size: 15px;
    font-weight: 600;
    width: fit-content;
    cursor: pointer;
}

.worldwide-tab-active {
    border-bottom: solid 2px black;
}

.worldwide-main {
    background: url('/public/images/worldwide.png');
    overflow-y: hidden !important;
}

.worldwide-tabs,
.worldwide-tab-options {
    min-width: 1116px;
}

.worldwide-main::-webkit-scrollbar {
    width: 20px;
    height: 8px;
}

.worldwide-section-title {
    text-shadow: 1px 2px 2px gray !important;
}

.worldwide-main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.worldwide-main::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 10px;
}

.worldwide-main::-webkit-scrollbar-thumb:hover {
    background: gray;
}

.worldwide-tab-option-title {
    font-size: 15px !important;
}

.worldwide-tab-options {
    display: none !important;
}

.worldwide-tab-options-active {
    display: flex !important;
}

.tip-card {
    width: 222px;
    height: 222px;
}

.card-width {
    width: 222px;
    height: 260px;
}


.additonal-step-section {
    background: url('/public//images/additonal-step-img.png');
    padding-top: 70px !important;
    padding-bottom: 70px !important;
}

.additional-step-card {
    width: 18rem;
    border-radius: 0% !important;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    background: rgba(255, 255, 255, 0.84);
}



.review-main-section {
    background: url('/public//images/reviews-bg.png');
    padding-top: 70px !important;
    padding-bottom: 70px !important;
}

.review-owl-item-image {
    width: 43% !important;
    height: 22vh;
    object-fit: cover;
    border-radius: 7px 0px 0px 7px;
    opacity: 0.9
}

.review-owl .owl-stage-outer .owl-stage .owl-item .item {
    padding-bottom: 0px !important;
    box-shadow: none;
    background: #ffffff59;
    border-radius: 14px;
}

.review-main-section .owl-carousel .owl-nav .owl-prev {
    /* display: none !important; */
    margin-left: 9px;
}

.review-main-section .owl-carousel .owl-nav .owl-next {
    margin-right: 8px;
}

.review-main-section .owl-carousel .owl-nav button {
    font-weight: 600;
    transform: scale(4.5);
    border: 0 !important;
    outline: 0 !important;
}

.review-main-section .owl-carousel .owl-nav button:hover {
    border: 0 !important;
    outline: 0 !important;
}



.review-main-section .owl-carousel .owl-nav {
    position: absolute;
    right: 0;
    width: 100%;
    bottom: 144px;
    justify-content: space-between !important;
    /* margin-right: 37px; */
}

.review-owl-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

.review-owl-info .review-owl-description {
    font-size: 15px;
    font-weight: 600;
}

.review-owl-star {
    margin-right: 8px;
    transform: scale(1.2);
    color: #ffb314;
}

.review-stars-container {
    background: white;
    border-radius: 8px;
}


/* Location Modal Style Ended*/

/* 
guestModal style start */

.counter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    align-items: flex-start;
}

.counter-header-count {
    color: #8C8C8C;
}

.counter-text {
    margin-top: -3%;
}

.counter-header {
    display: flex;
    width: 100%;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 37px;
    line-height: 50px;
    color: #000000;

}

.counter-header-text {
    font-size: 20px;
    margin-right: 10px;
}

.counter-header-count {
    font-size: 25px;

}

.counter-buttons {
    display: flex;
}

.counter-button {
    background-color: #fff;
    color: #333;
    border: none;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    font-size: 20px;
    cursor: pointer;
    margin: 0 20px;
}

/* Filter style  start */

.headerfilter {
    /* justify-content: center; */
    /* width: 338px; */
    /* height: 50px; */
    width: 80%;
    left: 284px;
    top: 35px;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 50px;
    color: #000000;
    text-align: center;
}

.refineline {
    color: black;
    border: 0;
    border-top: 1px solid;
    opacity: 1.25;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: -10px;
}

.spaceleft {
    margin-left: 3%;
}

.\.spaceleft10 {
    margin-left: 10%;
}

.Filtertxt {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 41px;
    color: #000000;
    margin-left: 2%;
}

.housedoorimg {
    border: 1px solid;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    padding: 2px;
    padding-left: 6px;
}

.formfilter {
    padding-left: 3rem;
}

.cashimg {
    border: 1px solid;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    padding: 2px;
    padding-left: 6px;
}

.Ranger {
    margin-left: 13%;
    margin-right: 10%;
}

.searchmodal {
    z-index: 1000;
}

.convenience {
    border: 1px solid;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    padding: 2px;
    padding-left: 3px;
}

span.riceranger {
    padding: 10px 20px 10px 20px;
}

.buildingimg {
    border: 1px solid;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    padding: 2px;
    padding-left: 6px;
}

.typebtn {
    border: 1px solid;
    display: block;
    height: 42px;
}

.property-type-list {
    display: flex;
    flex-wrap: wrap;
}

.property-type-list .list-group-item {
    border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color) !important;
    margin-right: 10px;
    margin-bottom: 10px;
    border-radius: 0% !important;
}

.property-type-list li button {
    background: transparent;
    border: 0;
    display: flex;
}

.coveniencehead {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 41px;
    color: #000000;
    margin-bottom: 0;
    margin-left: 46px;

}

.bed {
    border: 1px solid;
    border-radius: 20px;
    height: 30px;
    width: 30px;
    padding: 2px;
    padding-left: 3px;
}

.filtermodal {
    max-height: 642px;
    overflow-y: auto;

}

.Roomandbedstxt {
    padding-left: 45px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;

}

.Roomandbedtype {
    cursor: pointer;
    background-color: #B4EBF9;
    width: 40px;
    /* padding-left: 5px; */
    /* padding: 5px; */
    border-radius: 10px;
    padding-top: 4px;
    height: 35px;
    justify-content: center;
    text-align: center;
}

.instantbooktxt {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 26px;
    padding-left: 46px;
}

.switchbtn {
    height: fit-content;
}

.clearallbtn {
    text-decoration: underline;
    cursor: pointer;
}



.service-card {
    border: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
}

.service-card:hover {
    box-shadow: 3px 4px 10px #888888 !important;
}

.rental-main {
    padding-top: 200px;
    padding-bottom: 200px;
    background: url('/public/images/furniture.jpg');
    background-size: cover;
    background-position: bottom;
}

.rental-title {
    text-shadow: 1px 2px 5px black !important;
}

.rental-main::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.4);
}

.paragraphWidth {
    width: 80%;
}

.rental-post-button {
    border: 0;
    background: #ffffff94;
    border-radius: 10px;
}

.rental-post-button-inner {
    font-size: 26px;
    font-weight: 700;
}

.section {
    margin-top: 85px !important;
}

.thingstoexplore-section {
    padding-top: 240px;
    padding-bottom: 240px;
    background: url('/public/images/thingsToExplore.png');
    background-position: center;
    background-size: cover;
    position: relative;
    padding-left: 0px;
}

.thingstoexplore-section::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
}

.thingstoexplore-main {
    display: flex;
    flex-direction: column;
    justify-content: end;
    position: absolute;
    bottom: 0;
    margin-bottom: 23px;
    margin-left: 28px;
}

.thingstoexplore-title {
    color: white;
    text-shadow: 1px 2px 5px black !important;
}

.thingstoexplore-description {
    color: white;
    font-size: 15px;
    text-shadow: 1px 2px 5px black !important;
}

.thingstoexplore-button {
    border: 0;
    background: #0579c1;
    color: white;
    font-size: 17px !important;
    width: fit-content;
    border-radius: 14px;
    padding: 9px 16px !important;
    box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
    transition: all ease-in 0.3s;
}

.thingstoexplore-button:hover {
    background: var(--button-hover-color);
}

.prefooter-submit-button {
    font-size: 15px;
    background-color: var(--theme-color);
}

.prefooter-submit-button:hover {
    background-color: var(--button-hover-color);
}

.footer-mail-icon {
    transform: scale(1.3);
}

.menu-item {
    position: relative;
    transition: all 0.3s ease-in;
}

.menu-item:hover {
    color: var(--theme-color);
}

.menu-item span:nth-child(1) {
    position: absolute;
    width: 100%;
    height: 2px;
    background: var(--theme-color);
    bottom: 0;
    left: 0;
    top: 25px;
    transform: scaleX(0);
    transition: all 0.3s ease-in;
}

.menu-item:hover span:nth-child(1) {
    transform: scaleX(1);
    transition: all 0.3 ease-in !important;
}



.owl-carousel .owl-item {
    cursor: pointer;
}

.owl-carousel .owl-item p {
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    cursor: pointer;
}

.owl-carousel .owl-item .owl-title {
    font-size: 15px;
    font-weight: 600;
    transition: all 0.3s ease-in;
}

.owl-carousel .owl-item:hover .owl-title {
    color: var(--theme-color);
}

/* Best Places Card Animation */
.owl-carousel .owl-item .bestplace-outline-child-1 {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50.5%;
    height: 3px;
    background-color: var(--theme-color);
    transform: scaleX(0);
    transform-origin: right;
    transition: all ease-in-out 0.3s;
    transition-delay: 0.6s;
}

.owl-carousel .owl-item:hover .bestplace-outline-child-1 {
    transform: scaleX(1);
    transform-origin: right;
    transition-delay: 0s;
}

.owl-carousel .owl-item .bestplace-outline-child-2 {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 50.5%;
    height: 3px;
    background-color: var(--theme-color);
    transform: scaleX(0);
    transform-origin: left;
    transition: all ease-in-out 0.3s;
    transition-delay: 0.6s;
}

.owl-carousel .owl-item:hover .bestplace-outline-child-2 {
    transform: scaleX(1);
    transform-origin: left;
    transition-delay: 0s;
}

.owl-carousel .owl-item .bestplace-outline-child-3 {
    position: absolute;
    right: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background-color: var(--theme-color);
    transform: scaleY(0);
    transform-origin: bottom;
    transition: all ease-in-out 0.3s;
    transition-delay: 0.3s;
}

.owl-carousel .owl-item:hover .bestplace-outline-child-3 {
    transform: scaleY(1);
    transform-origin: bottom;
    transition-delay: 0.3s;
}

.owl-carousel .owl-item .bestplace-outline-child-4 {
    position: absolute;
    left: 0;
    top: 0;
    width: 3px;
    height: 100%;
    background-color: var(--theme-color);
    transform: scaleY(0);
    transform-origin: bottom;
    transition: all ease-in-out 0.3s;
    transition-delay: 0.3s;
}

.owl-carousel .owl-item:hover .bestplace-outline-child-4 {
    transform: scaleY(1);
    transform-origin: bottom;
    transition-delay: 0.3s;
}

.owl-carousel .owl-item .bestplace-outline-child-5 {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    height: 3px;
    background-color: var(--theme-color);
    transform: scaleX(0);
    transform-origin: left;
    transition: all ease-in-out 0.3s;
}

.owl-carousel .owl-item:hover .bestplace-outline-child-5 {
    transform: scaleX(1);
    transform-origin: left;
    transition-delay: 0.6s;
}

.owl-carousel .owl-item .bestplace-outline-child-6 {
    position: absolute;
    right: 0;
    top: 0;
    width: 50%;
    height: 3px;
    background-color: var(--theme-color);
    transform: scaleX(0);
    transform-origin: right;
    transition: all ease-in-out 0.3s;
}

.owl-carousel .owl-item:hover .bestplace-outline-child-6 {
    transform: scaleX(1);
    transform-origin: right;
    transition-delay: 0.6s;
}

.header-main {
    background-color: #D1EFF6 !important;
}

.menu-collapse {
    background-color: #D1EFF6 !important;
}

.mbl-auth-menu-main {
    background-color: #D1EFF6 !important;
}

.footer-bottom-main {
    background-color: #D1EFF6;
}

* {
    font-family: 'Montserrat', sans-serif;
}

.footer-item-header {
    font-size: 15px;
    font-weight: 600;
}

.footer-bottom-item {
    font-size: 15px !important;
    color: #717171;
    cursor: pointer;
}

.footer-bottom-item:hover {
    color: black;
    text-decoration: underline;
}

.footer-bottom-logo {
    height: 12vh !important;
}

.menu-mbl-item {
    font-size: 17px !important;
    font-weight: 500 !important;
}

.footer-item-header-mbl-icon {
    display: none;
}



.footer-bottom-mbl-active {
    animation: footerBottomItemActive ease 0.3s forwards;
}

.footer-bottom-mbl-inactive {
    animation: footerBottomItemInactive ease 0.3s forwards;
}







.footer-item-header-mbl {
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.footer-bottom-items-container {
    max-height: 100vh !important;
}

.footer-bottom-mbl-dropdown {
    border-bottom: solid 1px black;
}

.footer-item-header-mbl-icon {
    transition: all ease 0.3s;
}

.footer-bottom-2nd-icon {
    margin-right: 32px;
    transform: scale(1.3);
    transition: all ease 0.3s;
    cursor: pointer;
    height: fit-content;
}

.footer-bottom-2nd-icon:hover {
    color: var(--theme-color);
    transform: scale(1.5);
}

.footer-item-header-2nd {
    display: revert !important;
    font-size: 15px;
    font-weight: 600;
}



.section-titles {
    font-size: 20px !important;
}

.rental-post-button-inner {
    font-size: 16px !important;
}

.thingstoexplore-description {
    font-size: 15px !important;
}

.thingstoexplore-button {
    font-size: 15px !important;
}

.whatyouwant-upper-text {
    font-size: 15px !important;
    color: #717171 !important;
}

.whatyouwant-location {
    font-weight: 600;
}

.whatyouwant-location,
.whatyouwant-price {
    font-size: 15px;
    color: black;
}

.whatyouwant-description,
.whatyouwant-date,
.whatyouwant-time {
    font-size: 15px;
    color: #717171;
}

/* .destination-main-title {
    font-size: 15px !important;
} */

.destination-arrow-icon {
    position: relative;
    right: 13px;
}

.destination-arrow-icon::before {
    content: "";
    position: absolute;
    top: -2px;
    width: 1px;
    height: 61%;
    background-color: #97C1C6;
    z-index: 1;
    transform: rotate(-35deg);
}

.destination-arrow-icon::after {
    content: "";
    position: absolute;
    bottom: -2px;
    width: 1px;
    height: 61%;
    background-color: #97C1C6;
    z-index: 1;
    transform: rotate(35deg);
}

.footer-bottom-item-disclaimer:hover {
    text-decoration: none !important;
    color: #717171 !important;
}

.search-dropdown {
    position: absolute;
    width: 100%;
    margin-top: 47px;
}

.search-dropdown-main {
    width: 100%;
    background-color: white;
    color: black;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.datepicker-display .search-dropdown {
    border-radius: 10px !important;
    overflow: hidden;
}

.search-dropdown {
    z-index: 101;
}

.destination-input input {
    font-size: 15px !important;
}

.destination-searchbar {
    width: 90%;
    font-size: 15px !important;
    outline: none !important;
}

.destination-main-search-item {
    color: #545454;
    font-size: 15px;
    font-weight: 600;
    font-family: unset !important;
    user-select: none;
}

.destination-main-search-item-icon {
    margin-right: 10px;
}

.destination-main-search-item-title {
    font-size: 15px;
    font-weight: 600;
}

.destination-searchbar-icon {
    margin-left: 6px;
    cursor: pointer;
}

.verify-2nd-title {
    font-size: 20px;
    font-weight: 600;
}

.verify-2nd-image {
    width: 100%;
    min-height: 40vh;
    object-fit: cover;
}

.verify-2nd-list-item-main {
    width: 100%;
    word-wrap: break-word;
    cursor: pointer;
    font-size: 16px;
}


.verify-2nd-list-items-1st-column {
    height: fit-content;
}

.verify-2nd-list-item-main-icon::before {
    transform: scale(1.2);
    margin-right: 4px;
}

.menu-profile .dropdown .dropdown-menu {
    border: 0;
    box-shadow: rgb(0 0 0 / 9%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
}

.amenities-icon {
    transform: scale(1.2);
    margin-right: 4px;
    width: auto;
    height: 20px;
}

.verify-2nd-list-item {
    cursor: pointer;
    background-color: white;
    transition: all ease 0.3s;
}

.verify-2nd-list-item:hover {
    background-color: rgb(218, 217, 217);
}

.title-account-page {
    font-size: 30px;
    font-weight: 900;
    color: #000000;
}


.guest-dropdown-counter-minus,
.guest-dropdown-counter-text {
    font-size: 15px;
    margin-right: 31px;
}

.guest-dropdown-counter-minus,
.guest-dropdown-counter-plus {
    color: #1AB2C5;
    cursor: pointer;
}

.guest-dropdown-counter-text {
    width: 56px !important;
    margin: 0px 5px;
    position: relative;
    display: inline-block;
    text-align: center;
}

.guest-dropdown-counter-minus {
    font-size: 15px;
}

.guest-dropdown-counter-text {
    pointer-events: none;
}

.guest-dropdown-counter-minus,
.guest-dropdown-counter-plus {
    color: var(--theme-color);
    cursor: pointer;
}

.guest-dropdown-counter-text::selection {
    background-color: none;
    color: revert;
}

.guest-dropdown-counter-minus::before,
.guest-dropdown-counter-plus::before {
    transform: scale(1.4);
}

.guest-dropdown-counter-radio-1 {
    margin-right: 68px;
}


.form-check-input:checked {
    background-color: var(--theme-color) !important;
    border-color: var(--theme-color) !important;
}

.guest-dropdown-counter-radio {
    transform: scale(1.2);
    cursor: pointer;
}

.guest-dropdown-counter-pets {
    font-size: 15px;
    margin-right: 8px;
}

.search-dropdown {
    transform: translateY(45px);
    opacity: 0;
    pointer-events: none;
}

.dropdown-active {
    animation: destinationActiveDropdown 0.4s ease forwards;
    transition: all 0.4s ease;
    pointer-events: all !important;
}

.dropdown-inactive {
    animation: destinationInactiveDropdown 0.4s ease forwards;
    transition: all 0.4s ease;
    pointer-events: none;
}

.verify-3rd-list-item-main-icon {
    transform: scale(1.2);
    margin-right: 4px;
    margin-left: 15px;
    width: 40px;
    height: 40px;
    margin-top: 7px;
}

.entire-place-text {
    color: #ADADAD;
}

.date-range-picker {
    margin-top: 0px !important;
    box-shadow: rgb(0 0 0 / 30%) 0px 19px 38px, rgb(0 0 0 / 22%) 0px 15px 12px;
}

.rdrStaticRangeSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    color: var(--theme-color) !important;
}

.rdrDateDisplayItem {
    border-color: var(--theme-color) !important;
}

.rdrDayInPreview,
.rdrDayHovered {
    border-color: var(--theme-color) !important;
    outline-color: var(--theme-color) !important;
}

.date-range-btn {
    color: var(--theme-color) !important;
}

.date-range-btn:hover {
    color: white !important;
    background-color: var(--bulue-color) !important;
}

.destination-searchbar-holder {
    position: relative;
    padding: 5px 0px;
    border-bottom: solid 1px #adadad;
}

.search-bottom-outline {
    position: absolute;
    width: 100%;
    height: 0.2vh;
    background-color: black !important;
    left: 0;
    bottom: -1px;
    transform: scale(0);
    transform-origin: center;
    transition: all 0.3s ease;
}

.search-bottom-outline-active {
    transform: scale(1);
}

/* image upload section stared */
.drag-files-wrapper {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 40px;
}

.fileDropBox {
    padding: 4rem 4rem;
    border: 1px dashed gray;
    text-align: center;
    color: gray;
    border-radius: 8px;
}

.drag-file-image {
    font-size: 30px;
    color: #000000;
}

.drag-file-header {
    color: #000000;
    font-size: 20px;
}

.drag-file-text {
    font-size: 15px;
}

.drag-file-here {
    font-size: 15px;
    cursor: pointer;
}

.photos-drag-files-wrapper {
    max-width: 381px;
    margin-top: 40px;
    cursor: pointer;
    aspect-ratio: 2/0.8;
}

.photoDropBox {
    padding: 1rem 1rem;
    border: 1px dashed gray;
    text-align: center;
    color: gray;

    width: 100%;
}

.upload-main-image {
    max-width: 722px;
    ;
    width: 100%;
    height: auto;
}

.uploaded-image {
    max-width: 100%;
}

.uploaded-images {
    max-width: 743px;
    margin: auto;
}

/* image upload section Ended */
.property-title-field {
    max-width: 618px;
    width: 100%;
}

/* *************************************** Manage Account **************************************** */
.account-manage-icon {
    font-size: 20px;
    padding-left: 4px;
    width: 20px;
    height: auto;
}

.account-manage-title {
    font-size: 17px;
    font-weight: 700;
    ;
}

.account-manage-text {
    font-size: 15px;
    color: #ADADAD;
}

.account-manage-list-item {
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
}

.upload-photo {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 400;
    font-size: 17px;
    margin-left: 35px;
    color: #000000;
}

.idnentity-verfication-text {
    font-size: 15px;
    margin-left: 42px;
}

.get-badge-button {
    display: inline-block;
    padding: 0.8rem 1rem;
    cursor: pointer;
    background: #0579C1;
    border: 1px solid #000000;
    margin-left: 42px;
}

.confirmation-title {
    font-weight: 700;
    font-size: 17px;

}

.information-card {
    background: #FFFFFF;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
}

.confirmation-section {
    margin-left: 42px;
}

.confirmation-check {
    color: #0579c1;
    font-size: 17px;
}

.business-mail-title {
    font-size: 20px;
    font-weight: 700;
}

.business-mail-text {
    color: #ADADAD;
    font-size: 15px;
}

.add-mail-btn {
    background: #0079C2;
}

.add-mail-btn:hover {
    background: #005B93;
}

.tax-information-title {
    font-size: 20px;
    font-weight: 700;
    color: #000000;
}

.recieve-title {
    font-size: 15px;
    font-weight: 700;
    color: #000000;
}

.tax-info-contact-us {
    color: #0079C2;
    cursor: pointer;
}

.welcome-title {
    font-weight: 700;
    font-size: 25px;
}

.welcome-text {
    font-size: 15px;
}

.recieve-text {
    font-size: 15px;
    font-weight: 500;
}

.guest-count {
    position: absolute;
    right: 0;
    background: none;
    color: #837592;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 15px !important;
    margin-right: 7px;
    z-index: 102;
}

.loginInfoImg {
    width: 30%;
}

.security-width {
    width: 25%;
    color: var(--bulue-color)
}

.privacy-button button {
    background-color: var(--bulue-color);
}

.privacy-button button:hover {
    background-color: rgba(43, 135, 211, 0.932);
}

.privacy-input {
    width: 50%
}

.payment-button button {
    background-color: var(--bulue-color);
    width: 170px
}

.field-check-listing {
    vertical-align: middle;
}

.coupon-card {
    width: 50%;
}

.instant-book-table {
    width: 110px;
}

.edit-listing-text {
    text-decoration: underline;
    cursor: pointer;
    color: #0079C2;
}

.edit-listing-icon {
    color: #0079C2;
    font-size: 15px;
    cursor: pointer;
}

.listing-property-text {
    font-size: 18px;
    font-weight: 700;
}

.listing-text {
    font-size: 15px;
}

.listing-property-done {
    color: #188907;
}

/* WorkTogether */
.work-Together-text {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    text-transform: uppercase;

    color: #FFFFFF;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Work together styling */
.divBackgroundImageWork {
    background-image: url('../../public/images/bgWork.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
}
.divBackgroundImageRest {
    background-image: url('../../public/images/resteasy.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
    width: 100%;
}
.rest-easy-text{
    font-family: 'Open Sans';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 34px;
text-align: center;
text-transform: uppercase;

color: #FFFFFF;
text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.vacation-rent-div{
    margin-top: -220px;
}
.work-together-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 41px;
    color: #0D7BC4;
}

.work-together-img {
    width: 100%;
    height: auto;
}

.work-together-host-property {
    background: #0D7BC4;
}

.work-together-host-property-txt {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 34px;
    text-align: center;

    color: #FFFFFF;
}

.work-together-host-property-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 48px;
    color: #FFFFFF;
}

.btn-list-property {
    background: #8AE8FE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
}

.btn-list-property:hover {
    background: #75c9dc;
}

.frequent-question {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    /* identical to box height */


    color: #0D7BC4;
}

.question-title {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 37px;
    color: #000000;
    cursor: pointer;
}

.help-img {
    width: inherit;
}

/* New to vacation Rental  */
.launch-property {
    background: #D1EFF6;
}

.launch-property-title {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 41px;
    text-transform: uppercase;
    padding-top: 5%;
    padding-bottom: 4%;
    margin-bottom: 7%;
}

.launch-property-img {
    transform: translateY(-56%);
    /* width: 50%; */
    width: 100%;
    /* height: auto; */

}

/* .vacation-rent-bg-img{
    background: url(http://localhost:3000/static/media/Group12.a0784f1….png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 490px;
    padding-bottom: 150px;
    height: 100%;
    width: 100%;
} */
/* style listing start */
.btn-search-listing {
    background-color: #0579C1;
    color: white
}

.image-listing {
    width: 200px;
}

.hamburger {
    position: relative;
    width: 29px;
    height: 24px;
    display: flex;
    justify-content: end;
    align-items: center;
    cursor: pointer;
}

.hamburger span {
    position: absolute;
    display: inline-block;
    width: 28px;
    height: 2px;
    border-radius: 8px;
    background-color: #000;
    transform: scaleY(1.2);
}

.hamburger span:nth-child(1) {
    margin-top: -19px;
    transform-origin: left;
    transition: transform 400ms ease;
}

.auth-spinner {
    width: 20px;
    height: 20px;
    font-size: 13px;
}

.hamburger span:nth-child(2) {
    transition: opacity 300ms ease;
}

.hamburger span:nth-child(3) {
    margin-top: 19px;
    transform-origin: left;
    transition: transform 400ms ease;
}

.ham-burger-active span:nth-child(1) {
    transform: rotate(42deg);
}

.ham-burger-active span:nth-child(2) {
    opacity: 0;
}

.ham-burger-active span:nth-child(3) {
    transform: rotate(-42deg);
}

.control-dots,
.carousel-status {
    z-index: -1 !important;
}

.not-loaded {
    position: relative;
    overflow: hidden;
    opacity: 0;
    animation: notLoaded 0.9s linear infinite;
  }

.not-loaded::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #e9e9e9;
    z-index: 2;
    opacity: 1;
}

@keyframes notLoaded {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}
  

@keyframes preloader {
    100% { transform: scale(2); }
  }

@media(min-width: 775px) {
    .daterange-display {
        display: none !important;
    }
}

@media(max-width: 775px) {
    .datepicker-display {
        display: none !important;
    }
}

@media screen and (max-width: 775px) {
    .datepicker-mbl-display {
        display: revert !important;
    }
}

/* Media Queries */

@media screen and (max-width: 1025px) {
    .figure-text-sizing h2 {
        font-size: 18px;
    }

    .figure-text-sizing p {
        font-size: 14px;
    }

    .destination-input {
        font-size: 15px !important;
    }

    .wehost-card-position {
        top: -155px;
    }

    .wehost-card-position-2 {
        top: -135px;
    }

    .wehost-bg-img {
        padding-top: 155px !important;
    }

    .wehost-bg-img-text h1 {
        font-size: 20px;
        margin-bottom: 40px;
    }

}

@media screen and (max-width: 775px) {
    .filter-button-size-style {
        padding: 4px !important;
    }


    .image-div {
        height: 200px;
    }

    .text {
        font-size: 1.5em !important;
    }

    /* .destination-main-title {
        font-size: 46px !important;
    } */
    .rowPaddingStyle {
        padding-left: 70px;
        padding-right: 70px;
    }

    .datepicker-display {
        display: none;
    }

    .daterange-display {
        display: block;
    }

    .datepicker-pc-display {
        display: none;
    }

    .wehost-card-position {
        top: 0;
        margin-bottom: 19px;
    }

    .wehost-card-position-2 {
        top: 0;
    }

    .wehost-h6-div {
        width: 82%
    }

    .wehost-bg-img {
        padding-top: 230px !important;
    }

    .wehost-profile-text {
        width: 90%;
        text-align: justify;
    }

    .loginInfoImg {
        width: 50%;
    }

    .security-width {
        width: 40%;
    }

    .privacy-input {
        width: 60%
    }

}

@media screen and (max-width: 425px) {
    .filter-button-size-style {
        padding: 2px !important;
        font-size: 1rem !important;
    }

    .main-text-size-style h1 {
        font-size: 20px !important;
    }


    .figure-text-sizing h2 {
        font-size: 15px;
    }

    .figure-text-sizing p {
        font-size: 10px;
    }

    .signin-input-container input {
        margin-bottom: 15px;
    }

    .signin-input-container label {
        font-size: 12px;
    }

    .signin-input-container p {
        font-size: 12px;
    }

    .sign-in-checkbox {
        width: 85%;
    }

    .signin-input-width {
        width: 85%;
    }

    .react-tel-input {
        width: 85% !important
    }

    .sign-in-text-size h1 {
        font-size: 30px;
    }

    .sign-in-text-size p {
        font-size: 15px;
    }

    .sign-in-text-size span {
        font-size: 38px;
    }

    .propertyHeaderWidth {
        width: 150px;
        height: 80px;
    }

    .header-section-padding {
        padding: 0 1em;
    }

    .welcome-text h1 {
        font-size: 18px;
        font-weight: 600;
    }

    .welcome-text h3,
    h2 {
        font-size: 15px;
        font-weight: 600;
    }

    .welcome-text p {
        font-size: small;
        width: 85%;
    }

    .welcome-text h6 {
        font-size: 15px;
        font-weight: 400;
    }

    .welcome-images {
        width: 70px;
        height: 70px;
    }

    .firstStep-img {
        width: 300px;
    }

    .wehost-main-container {
        padding-left: 8px;
    }

    .wehost-main-container h1 {
        font-size: 15px;
    }

    .wehost-main-container p {
        font-size: 14px;
        width: 100%;
    }

    .wehost-h6-div {
        width: 100%
    }

    .wehost-button button {
        width: 120px
    }

    .wehost-bg-img-text h1 {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .wehost-profile-text {
        width: 100%;
        text-align: justify;
    }

    .loginInfoImg {
        width: 80%;
    }

    .security-width {
        text-align: end !important;
        width: 54%;
    }

    .privacy-input {
        width: 100%
    }

    .coupon-card {
        width: 90%;
    }


}

@media(max-width:1122px) {
    .header-logo {
        height: 9vh !important;
    }
}

@media(max-width:1071px) {
    .items-section {
        display: none !important;
    }

    .menu-dropdown {
        display: flex !important;
    }
}

@media(max-width:617px) {
    .header {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
}

@media (max-width:600px) {
    .text {
        font-size: 1.5em !important;
    }
}

@media(max-width: 1008px) {
    .table-responsive-lg {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }
}

@media(min-width: 1392px) {
    .destination-input span .bi-search {
        margin-left: 282px;
    }
}

@media(min-width: 1999px) {
    .bottom-buttons {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }
}

@media(max-width: 1306px) {
    /* .destination-main-title {
        font-size: 58px !important;
    } */
}

@media(max-width: 1199px) {
    .destination-main-title {
        font-size: 3rem !important;
    }

    .formulatxt {
        font-size: 1.2rem !important;
    }
}

@media(max-width: 889px) {}

@media(max-width: 799px) {
    .destination-main-title {
        font-size: 2.1rem !important;
    }

    .formulatxt {
        font-size: 15px !important;
    }
}

@media(max-width: 899px) {
    /* .destination-main-title {
        font-size: 48px !important;
    } */
}

@media(max-width: 555px) {
    .destination-main-title {
        font-size: 1rem !important;
    }
}

@media(max-width: 1199px) {
    .destination-input span .bi-search {
        margin-left: 187px;
    }
}

@media(max-width: 767px) {
    .destination-input input {
        text-align: start !important;
        font-size: 14px !important;
    }

    .destination-input span .bi-search {
        position: relative;
        margin-left: 0px;
    }

    .destination-input a {
        font-size: 14px;
    }
}

/* 
@media(max-width: 549px) {
    .destination-input span .bi-search {
        margin-left: 168px;
    }
}

@media(max-width: 501px) {
    .destination-input span .bi-search {
        margin-left: 152px;
    }
}

@media(max-width: 425px) {
    .destination-input span .bi-search {
        margin-left: 102px;
    }
} */

@media screen and (max-width: 775px) {
    .explore {
        width: 35%;
    }

}

@media screen and (max-width: 475px) {
    .explore {
        width: 70%;
    }

    button {
        width: 75px;
        font-size: 15px !important;
        padding: 1px
    }
}

@media(max-width:981px) {
    .explore-images {
        flex-direction: column;
    }

    .images-section-1 {
        width: 100%;
    }

    .images-section-2 {
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
    }

    .images-section-3 {
        width: 100%;
        margin-left: 0%;
        margin-top: 10px;
    }

    .inner-section-3 {
        margin-top: 10px !important;
    }

    .overly-2 {
        height: 47.5%;
    }
}

@media(max-width:953px) {

    .explore-image-1,
    .explore-image-2,
    .explore-image-3,
    .explore-image-4 {
        height: 35vh !important;
    }

    .overly-2 {
        height: 49.5% !important;
    }
}

@media(max-width:471px) {

    .explore-image-1,
    .explore-image-2,
    .explore-image-3,
    .explore-image-4 {
        height: 25vh !important;
    }
}

@media screen and (max-width: 1024px) {
    .figCaptionDisplay {
        font-size: 1.5rem;
        bottom: 10rem;
    }

}

@media screen and (max-width: 768px) {
    .figCaptionDisplay {
        font-size: 1rem;
        bottom: 5rem;
    }
}

@media screen and (max-width: 475px) {
    .figCaptionDisplay {
        font-size: 0.8rem;
        bottom: 2.5rem;
    }

    .backcolor {
        padding: 2px;
        width: 200px;
    }
}

@media screen and (max-width: 375px) {
    .figCaptionDisplay {
        font-size: 0.8rem;
        bottom: 2rem;
    }

    .backcolor {
        padding: 2px;
        width: 200px;
    }

    .date-range-picker {
        left: 1px;
        width: 99%;
        overflow: hidden;
    }
}

@media screen and (max-width: 770px) {
    .things-to-explore-text h2 {
        font-size: 17px !important;
    }

    .things-to-explore-text h5 {
        font-size: 10px !important;
        margin-bottom: 0;
    }

    .things-to-explore-text button {
        font-size: 8px !important;
        padding: 1px !important;
    }

    .carousel-caption {
        bottom: -0.95rem !important;
        left: 2% !important;
    }
}

@media screen and (max-width: 375px) {
    .things-to-explore-text h2 {
        font-size: 10px !important;
        text-shadow: 1px 3px 4px #FFFBEB;
        margin-bottom: 0;
    }

    .things-to-explore-text h5 {
        font-size: 7px !important;
        text-shadow: 1px 3px 4px #FFFBEB;
        margin-bottom: 0;
    }

    .things-to-explore-text button {
        width: 8em;
        font-size: 6px !important;
        padding: 1px !important;
    }

    .carousel-caption {
        bottom: -0.95rem !important;
        left: 2% !important;
    }
}

@media screen and (max-width: 775px) {
    button {
        padding: 4px !important;
    }

    .image-div {
        height: 200px;
    }
}

@media screen and (max-width: 475px) {
    button {
        padding: 2px !important;
        font-size: 15px !important;
    }

    .main-text-size-style h1 {
        font-size: 20px !important;
    }

    .multipleImages {
        height: 65px;
    }
}

@media (max-width:770px) {

    /* .text {
        font-size: 1.5em !important;
    } */
    /* .destination-main-title {
        font-size: 46px !important;
    } */
    .rowPaddingStyle {
        padding-left: 70px;
        padding-right: 70px;
    }
}

@media (max-width:425px) {
    .center {
        padding-top: 40px;
        padding-bottom: 80px;
    }

    .destination-main-title {
        font-size: 1.1rem !important;
    }

    .formulatxt {
        font-size: 0.9rem !important;
    }

    .rowPaddingStyle {
        padding-left: 50px;
        padding-right: 50px;
    }

}

@media screen and (max-width: 1440px) {
    .wehost-bg-img {
        padding-top: 218px;
    }
}

@media screen and (max-width: 650px) {
    .card-display-style {
        margin-right: 0px !important;
    }
}

@media(max-width:475px) {
    .review-main-section .owl-carousel .owl-nav .owl-prev {
        margin-left: -34px;
    }

    .review-main-section .owl-carousel .owl-nav .owl-next {
        margin-right: -13px;
    }
}

@media(max-width:991px) {
    .worldwide-tab-option-title {
        text-align: start !important;
    }
}

@media(max-width:475px) {
    .review-main-section .owl-carousel .owl-nav {
        margin-right: -11px;
    }
}

@media only screen and (max-width:768px) {
    .mailsm {
        width: 100% !important;
    }
}

@media (min-width: 768px) {
    .col-md-7 {
        flex: 0 0 auto;
        width: 92.333333%;
    }
}

@media(max-width:750px) {
    .rental-title {
        font-size: 26px !important;
    }

    .rental-post-button-inner {
        font-size: 21px !important;
    }

    .rental-main {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    .thingstoexplore-title {
        font-size: 26px !important;
    }

    .thingstoexplore-description {
        font-size: 20px !important;
    }

    .thingstoexplore-button {
        padding: 6px 16px !important;
    }
}

@media(max-width:475px) {
    .rental-post-button {
        width: 237px !important;
        padding-top: 8px !important;
        padding-bottom: 6px !important;
    }

    .rental-main {
        padding-top: 130px;
        padding-bottom: 130px;
    }

    .rental-post-button-inner {
        font-size: 16px !important;
    }
}

@media(max-width:767px) {
    .thingstoexplore-section {
        padding-top: 180px;
        padding-bottom: 180px;
    }
}

@media(max-width:991px) {
    .footer-bottom-items-container {
        max-height: 3vh;
        margin-bottom: 20px;
        padding-bottom: 40px !important;
        overflow: hidden;
    }

    .footer-bottom-items-container .footer-item-header {
        display: flex;
        justify-content: space-between;
        border-bottom: solid 1px black;
        padding-bottom: 14px !important;
        cursor: pointer;
    }

    .footer-item-header-mbl-icon {
        display: block;
    }
}

@media(max-width:575px) {
    .footer-bottom-items-container {
        margin-bottom: 20px;
        overflow: hidden;
    }
}

@media(max-width:991px) {
    .footer-item-header {
        display: none !important;
    }

    .footer-bottom-items {
        display: none !important;
    }

    .footer-bottom-items-container {
        margin-bottom: 0px !important;
    }
}

@media(min-width:991px) {
    .footer-bottom-mbl-dropdown {
        display: none;
    }
}

@media(max-width:991px) {
    .footer-bottom-2nd-mbl-hide {
        display: none !important;
    }
}

@media(max-width:767px) {
    .verify-2nd-list-items {
        display: flex;
        justify-content: center;
    }
}

/* ********************************* ANIMATION STUF ************************************** */

@keyframes destinationInactiveDropdown {
    0% {
        transform: translateY(0px);
        opacity: 1;
    }

    100% {
        transform: translateY(45px);
        opacity: 0;
    }
}

/* Header Animations */
@keyframes dropdownActive {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0%);
    }
}

@keyframes dropdownInActive {
    0% {
        transform: translateX(0%);
    }

    100% {
        transform: translateX(-100%);
    }
}

@keyframes footerBottomItemActive {
    0% {
        max-height: 0vh;
    }

    100% {
        max-height: 100vh;
    }
}

@keyframes footerBottomItemInactive {
    0% {
        max-height: 100vh;
    }

    100% {
        max-height: 3vh;
    }
}

@keyframes destinationActiveDropdown {
    0% {
        transform: translateY(45px);
        opacity: 0;
    }

    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

@keyframes headerAuthItemActive {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes headerAuthItemInactive {
    0% {
        opacity: 1;
        transform: translateY(20px);
    }

    100% {
        opacity: 0;
        transform: translateY(0px);
    }
}



.launch-property-img {
    transform: translateY(calc(-22%));
}

@media(max-width:349px) {

    .launch-property-img {
        transform: translateY(calc(-18%));
    }

}

@media(max-width:360px) {

    .launch-property-img {
        transform: translateY(calc(-16%));
    }

}

@media(max-width:480px) {

    .launch-property-img {
        transform: translateY(calc(-24%));
    }

}

@media(min-width:588px) {

    .launch-property-img {
        transform: translateY(calc(-30%));
    }

}

@media(min-width:700px) {

    .launch-property-img {
        transform: translateY(calc(-32%));
    }

}

@media(min-width:780px) {

    .launch-property-img {
        transform: translateY(calc(-34%));
    }

}

@media(min-width:920px) {

    .launch-property-img {
        transform: translateY(calc(-36%));
    }

}

@media(min-width:1030px) {

    .launch-property-img {
        transform: translateY(calc(-38%));
    }

}

@media(min-width:1190px) {

    .launch-property-img {
        transform: translateY(calc(-40%));
    }

}

@media(min-width:1324px) {

    .launch-property-img {
        transform: translateY(calc(-42%));
    }

}

@media(min-width:1400px) {

    .launch-property-img {
        transform: translateY(calc(-44%));
    }

}

@media(min-width:1490px) {

    .launch-property-img {
        transform: translateY(calc(-46%));
    }

}

@media(min-width:1640px) {

    .launch-property-img {
        transform: translateY(calc(-54%));
    }

}